import styled from 'styled-components';
import { Text, Svg } from '../CheckMarkIcon/style';

export const Price = styled.div`
  display: flex;
  transition-duration: 0.5s;
  align-items: baseline;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-size: 96px;
  font-weight: 100;
  @media (max-width: 1680px) {
    font-size: 80px;
  }
  @media (max-width: 1440px) {
    font-size: 45px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 75px;
  }
  @media (max-width: 375px) {
    font-size: 50px;
  }
`;

export const Month = styled.div`
  display: flex;
  transition-duration: 0.5s;
  align-items: baseline;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-size: 40px;
  font-weight: 100;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
  @media (max-width: 375px) {
    font-size: 25px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.5s;
  font-style: normal;
  color: #474747;
  font-weight: 100;
  font-size: 35px;
  @media (max-width: 1680px) {
    font-size: 27px;
  }
  @media (max-width: 1440px) {
    font-size: 23px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 33px;
  }
  @media (max-width: 375px) {
    font-size: 25px;
  }
`;

export const SelectPlantBtn = styled.button`
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.5s;
  white-space: nowrap;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 30px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 2px;
  background: linear-gradient(180deg, #32b1e8 0%, #4898dd 100%);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);

  :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  padding: 50px;
  margin: 15px 0;
  cursor: default;
  transition-duration: 0.5s;
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  :hover {
    background: #6a4fe4;
    ${Price}, ${Month}, ${Title}, ${Text} {
      color: #fff;
    }
    ${SelectPlantBtn} {
      background: #6a4fe4;
      border: 1px solid #fff;
    }
    ${Svg} {
      fill: #fff;
    }
  }

  @media (max-width: 1680px) {
    max-width: 250px;
  }
  @media (max-width: 1440px) {
    max-width: 210px;
  }
  @media (max-width: 1024px) {
    max-width: 180px;
    padding: 25px;
  }
  @media (max-width: 768px) {
    margin: 10%;
    max-width: none;
  }
`;
