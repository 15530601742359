import React from 'react';
import { useTranslation } from 'react-i18next';
import frame from '../../../shared/assets/img/frame.png';
import { Text, Btn, Img, Wrapper } from './StyledComponents';

const PreviewGame = ({ onActive, onClick, gameTitle }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Img src={frame} alt="frame" />
      <Text>{gameTitle}</Text>
      <Btn onClick={onClick} active={onActive}>
        {t('home.12')}
      </Btn>
    </Wrapper>
  );
};

export default PreviewGame;
