import styled from 'styled-components';

export const AppWrapper = styled.div`
  direction: ${({ language }) => (language === 'he' ? 'rtl' : 'ltr')};
  &&& {
    .navbar-active {
      color: #a4a4f7 !important;
    }
  }
`;

export const Wrapper = styled.div``;
