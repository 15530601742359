import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #474747;
  text-align: ${({ language }) => (language === 'he' ? 'right' : 'justify')};
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export default Wrapper;
