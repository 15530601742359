import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 3% 0;
  border: 2px solid #f8f8f8;
  border-radius: 4px;
  @media (max-width: 1160px) {
    width: 47%;
  }
  @media (max-width: 768px), (max-height: 415px) {
    margin-bottom: 20px;
  }
  @media (max-width: 415px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Img = styled.img``;

export const ReadMore = styled(Link)`
  border: none;
  background: transparent;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  width: 100px;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  color: #474747;
`;

export const WrappText = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 140px;
  justify-content: space-between;
  margin: 20px;
  @media (max-width: 768px), (max-height: 415px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-weight: 500;
  font-size: ${({ size }) => size};
  @media (max-width: 768px), (max-height: 415px) {
    font-size: 13px;
  }
  @media (max-width: 415px) {
    font-size: 20px;
  }
`;

export const PostDescription = styled.p`
  margin: 27px 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #474747;
  @media (max-width: 768px), (max-height: 415px) {
    margin: 10px 0;
    font-size: 13px;
    line-height: 17px;
  }
`;
