import styled from 'styled-components';

export const Svg = styled.svg`
  transition-duration: 0.5s;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2% 0;
`;

export const Text = styled.div`
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.5s;
  font-size: 19px;
  color: #474747;
  padding-left: 25px;
  @media (max-width: 1680px) {
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 19px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
