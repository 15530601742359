import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from './PreviewGames/StyledComponents';

import PreviewGame from './PreviewGames';
import { StylePreviewGames, Wrapper, Title } from './StyledComponent';

const Games = () => {
  const [isActive, setIsActive] = useState(null);

  const { t } = useTranslation();

  const handler = (index) => {
    setIsActive(index);
  };

  const games = [
    {
      title: t('home.8')
    },
    {
      title: t('home.9')
    },
    {
      title: 'Memory game'
    },
    {
      title: 'Memory game'
    }
  ];

  return (
    <Wrapper>
      <Title>PLAYTHEM Games</Title>
      <Description>
        Engage clients with your self-branded game and give them way to get a
        gift. Games is a great way to extend the time visitors spend on your
        website and convert users into customers by giving them a reward at the
        end of the game.
      </Description>

      <StylePreviewGames>
        {games.map((value, index) => (
          <PreviewGame
            key={index.toString()}
            gameTitle={value.title}
            onActive={index === isActive}
            onClick={() => handler(index)}
          />
        ))}
      </StylePreviewGames>
    </Wrapper>
  );
};

export default Games;
