import React from 'react';
import { useLocation } from 'react-router';
import {
  ImgWrapper,
  PostImage,
  Title,
  Content,
  PostWrapper,
  Article,
  NavContainer,
  NavBtn,
  OtherPosts,
  OtherBlogCard,
  Img,
  ReadMore,
  WrappText,
  OtherTitle,
  PostDescriptio
} from './style';
import icon from '../../../shared/assets/icons/reade-more.svg';
import Posts from '../posts';

const description =
  'The number of online-stores are growing by the day. A typical online shopper has at least five stores to choose from while making a purchase. If the competition wasn’t already enough, each of these stores are also using the same tactics to reach their target customers. Be it social media, email marketing or even advertising campaigns, everyone is doing everything. So what is the one thing you can do to make your strategy stand out and increase Shopify sales?';

const shortenDescription = () => {
  const arr = description.split(' ');
  const shorterStr = arr.slice(0, 25);
  return `${shorterStr.join(' ')} ...`;
};

const getOtherPosts = (posts, pageNumber) => {
  const copyPosts = [...posts];
  copyPosts.splice(pageNumber - 1, 1);
  return copyPosts.slice(0, 3);
};

const Post = () => {
  const location = useLocation();
  const pageAddress = location.pathname.split('/');
  const pageNumber = +pageAddress[pageAddress.length - 1];

  const posts = Posts();
  const otherPosts = getOtherPosts(posts, pageNumber);

  return (
    <PostWrapper>
      <Title>Gamification in Sales - Gamification for Everyone</Title>
      <ImgWrapper>
        <PostImage src={posts[pageNumber - 1].img} alt="image" />
      </ImgWrapper>
      <Content>
        <Article>
          {posts[pageNumber - 1].post}
          <NavContainer>
            <NavBtn
              to={`/blog/post/${pageNumber - 1}`}
              disabled={pageNumber === 1}
            >
              Previous
            </NavBtn>
            <NavBtn
              to={`/blog/post/${pageNumber + 1}`}
              disabled={pageNumber === posts.length - 1}
            >
              Next
            </NavBtn>
          </NavContainer>
        </Article>

        <OtherPosts>
          {otherPosts.map((otherPost) => (
            <OtherBlogCard>
              <Img src={otherPost.img} />
              <WrappText>
                <OtherTitle size="30px" fontWeight={600}>
                  {otherPost.title}
                </OtherTitle>
                <PostDescriptio>
                  {shortenDescription(otherPost.description)}
                </PostDescriptio>
                <ReadMore to={otherPost.url}>
                  Read more
                  <img src={icon} alt="icon" />
                </ReadMore>
              </WrappText>
            </OtherBlogCard>
          ))}
        </OtherPosts>
      </Content>
    </PostWrapper>
  );
};

export default Post;
