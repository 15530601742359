import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  StartText,
  Title,
  Description,
  WrappBlock1,
  Img,
  WrappBlock2,
  Img2,
  WrappTextBlock1,
  WrappBlock3,
  Koma,
  Block3,
  WrappBlock4,
  Block4,
  ImgBlock4,
  Description2,
  WrappTitle
} from './style';

import img from '../../shared/assets/img/platform/img.svg';
import step1 from '../../shared/assets/img/platform/step1.png';
import step2 from '../../shared/assets/img/platform/step2.png';
import step3 from '../../shared/assets/img/platform/step3.png';
import startBg from '../../shared/assets/img/platform/start.png';
import StartBtn from '../../components/Buttons/StartBtn';
import GetStarted from '../../components/GetStarted';
import ScrollToTop from '../../components/ScrollToTop';

const Platform = ({ languages }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ScrollToTop />
      <StartText>
        <Title size="30px" fontWeight={500}>
          {t('software.1')}
        </Title>
        <Description>{t('software.2')}</Description>
      </StartText>

      <WrappBlock1>
        <Img languages={languages} src={step1} alt="any" />
        <WrappTextBlock1>
          <Title size="30px">{t('software.3')}</Title>
          <Description>{t('software.4')}</Description>
        </WrappTextBlock1>
      </WrappBlock1>
      <WrappBlock1 revers>
        <Img languages={languages} revers src={step2} alt="any" />
        <WrappTextBlock1>
          <Title size="30px">{t('software.5')} </Title>
          <Description>{t('software.6')}</Description>
        </WrappTextBlock1>
      </WrappBlock1>
      <WrappBlock1>
        <Img languages={languages} src={step3} alt="any" />
        <WrappTextBlock1>
          <Title size="30px">{t('software.7')}</Title>
          <Description>{t('software.8')}</Description>
        </WrappTextBlock1>
      </WrappBlock1>

      <WrappBlock2>
        <Img2 src={startBg} alt="start background" />
        <Title size="30px" color="#fff">
          {t('software.9')}
        </Title>
        <Description2>{t('software.10')}</Description2>
        <StartBtn />
      </WrappBlock2>

      <WrappBlock3>
        <Block3>
          <Koma>“</Koma>
          <Title size="36px">{t('software.11')}</Title>
          <Description>{t('software.12')}</Description>
        </Block3>
        <Block3>
          <Koma>“</Koma>
          <Title size="36px">{t('software.13')}</Title>
          <Description>{t('software.14')}</Description>
        </Block3>
        <Block3>
          <Koma>“</Koma>
          <Title size="36px">{t('software.15')}</Title>
          <Description>{t('software.16')}</Description>
        </Block3>
      </WrappBlock3>

      <GetStarted marginBottom="40px" />
      <WrappTitle>
        <Title size="36px">{t('software.20')}</Title>
      </WrappTitle>

      <WrappBlock4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('software.21')}</Title>
          <Description>{t('software.22')}</Description>
        </Block4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('software.23')}</Title>
          <Description>{t('software.24')}</Description>
        </Block4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('software.25')}</Title>
          <Description>{t('software.26')}</Description>
        </Block4>
      </WrappBlock4>
    </Wrapper>
  );
};

export default Platform;
