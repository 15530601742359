import React from 'react';
import icon from '../../../shared/assets/icons/reade-more.svg';
import {
  Img,
  Title,
  ReadMore,
  Wrapper,
  WrappText,
  PostDescription
} from './style';

const shortenDescription = (description) => {
  if (!description) return '';
  const arr = description.split(' ');
  const shorterStr = arr.slice(0, 25);
  return `${shorterStr.join(' ')} ...`;
};

const BlogCard = ({ img, link, title, description }) => {
  return (
    <Wrapper>
      <Img src={img} />
      <WrappText>
        <Title size="30px" fontWeight={600}>
          {title}
        </Title>
        <PostDescription>{shortenDescription(description)}</PostDescription>
        <ReadMore to={link}>
          Read more
          <img src={icon} alt="icon" />
        </ReadMore>
      </WrappText>
    </Wrapper>
  );
};

export default BlogCard;
