import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 220px;
  @media (max-width: 768px) {
    padding-top: 75px;
  }
`;

export const StartText = styled.div`
  display: flex;
  max-width: 700px;
  padding: 0 5%;
  text-align: center;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 470px) {
    width: 80%;
    text-align: start;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  white-space: nowrap;
  color: ${({ color }) => color || '#474747'};
  font-weight: 500;
  font-size: ${({ size }) => size};
  @media (max-width: 768px), (max-height: 415px) {
    white-space: break-spaces;
    font-size: 20px;
  }
  @media (max-width: 470px) {
    font-size: 17px;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 5% 0;
  font-size: 16px;
  color: #474747;
  @media (max-width: 768px), (max-height: 415px) {
    white-space: break-spaces;
  }
  @media (max-width: 470px) {
    font-size: 14px;
    margin: 3% 0;
  }
`;

export const Description2 = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  width: 429px;
  font-style: italic;
  margin: 30px 0 40px;
  font-size: 16px;
  color: #fff;
  @media (max-height: 415px) {
    margin: 20px 0;
    width: 40%;
    font-size: 13px;
  }
  @media (max-width: 768px) {
    margin: 5px 0;
    width: 50%;
    font-size: 10px;
  }
`;

export const WrappBlock1 = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  margin-top: 5%;
  flex-flow: ${({ revers }) => {
    return revers ? 'row-reverse' : 'row';
  }};
  @media (max-width: 768px) {
    margin-top: 10%;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const WrappBlock2 = styled.div`
  position: relative;
  display: flex;
  padding: 0 5%;
  margin-top: 5%;
  height: 477px;
  width: 980px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-height: 415px) {
    justify-content: flex-start;
    padding: 30px 5% 0;
    height: 300px;
    width: 80%;
  }
  @media (max-width: 786px) {
    height: 140px;
    width: 80%;
  }
`;

export const Img = styled.img`
  width: 50%;
  margin: 0 5% 0 0;
  ${({ languages }) => {
    return languages === 'he' && 'margin: 0 0 0 5%';
  }};
  ${({ revers, languages }) => {
    return languages === 'he' && revers && 'margin: 0 5% 0 0 ';
  }};
  ${({ revers, languages }) => {
    return languages !== 'he' && revers && 'margin: 0 0 0 5%';
  }};

  @media (max-width: 425px) {
    width: 100%;
    margin: 0;
  }
`;

export const WrappTextBlock1 = styled.div`
  width: 50%;
  @media (max-width: 425px) {
    width: 100%;
    margin-top: 5%;
  }
`;

export const Img2 = styled.img`
  position: absolute;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const WrappBlock3 = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 5%;
  @media (max-width: 1024px) {
    height: 35vw;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    height: 140vw;
  }
  @media (max-width: 320px) {
    height: 180vw;
  }
`;

export const Koma = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 144px;
  line-height: 0;
  color: #33b0e7;
`;

export const Block3 = styled.div`
  width: 18%;
  @media (max-width: 1024px) {
    width: 30%;
  }
  @media (max-width: 425px) {
    width: 80%;
    margin-top: 40px;
  }
`;

export const Round = styled.div`
  width: 70px;
  height: 70px;
  margin-bottom: 5%;
  border-radius: 50px;
  background: linear-gradient(
    155.62deg,
    #ff55e1 -50.61%,
    #9b3db4 78.87%,
    #fd9946 224.06%
  );
`;

export const WrappBlock4 = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
  width: 90%;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Block4 = styled.div`
  width: 20%;
  @media (max-width: 1024px) {
    width: 30%;
  }
  @media (max-width: 425px) {
    width: 80%;
    margin-top: 40px;
  }
`;

export const ImgBlock4 = styled.img`
  width: 100%;
  margin-bottom: 5%;
`;

export const WrappTitle = styled.div`
  margin-top: 5%;
  //@media (max-width: 425px) {
  //  margin-top: 35%;
  //}
`;
