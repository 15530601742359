import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 220px;
  @media all and (max-width: 768px), (max-height: 415px) {
    padding-top: 75px;
  }
`;

export const WrappText = styled.div`
  display: flex;
  max-width: 700px;
  padding: 0 10%;
  text-align: start;
  min-height: 120px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  white-space: nowrap;
  color: #474747;
  font-weight: 500;
  text-align: center;
  font-size: ${({ size }) => size};
  @media all and (max-width: 768px), (max-height: 415px) {
    font-size: 30px;
  }
  @media all and (max-width: 415px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 5% 0;
  font-size: 16px;
  color: #474747;
  @media (max-width: 470px) {
    font-size: 14px;
  }
`;

export const WrappBlogs = styled.div`
  margin: 70px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media all and (max-width: 768px), (max-height: 415px) {
    margin: 20px 0 0;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
`;

export const PageBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  width: 37px;
  height: 37px;
  color: ${({ active }) => (active ? '#fff' : '#000')};
  background: ${({ active }) =>
    active ? 'linear-gradient(180deg, #32b1e8 0%, #4898dd 100%)' : 'none'};
  border-radius: 4px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;
