import React from 'react';
import { Wrapper, Title, Block, Description, Round } from './style';

const UserReviews = () => {
  return (
    <Wrapper>
      <Block>
        <Round />
        <Title size="36px">James Trevor</Title>
        <Description>
          If you are serious about promoting your store and re-selling back to
          potential customers, you NEED this add on. We&apos;ve generated nearly
          6000 email address in 5 months, just from running it on the store!
        </Description>
      </Block>
      <Block>
        <Round />
        <Title size="36px">Dream-team shop</Title>
        <Description>
          Great customer service. The popups have been very helpful in terms of
          upsell. They are very responsive. They even jumped in on a Sunday to
          help resolve an issue. I&apos;m very pleased with this application and
          their level of professionalism.
        </Description>
      </Block>
      <Block>
        <Round />
        <Title size="36px">Meth Cohn</Title>
        <Description>
          We like your product. Memory - is a SENSIBLE game. We have +20% leads
          from ONE monts. wAITING FOR NEW GAMES.
        </Description>
      </Block>
    </Wrapper>
  );
};

export default UserReviews;
