import React from 'react';
import { useTranslation } from 'react-i18next';
import Premium from '../../components/Premium';
import background from '../../shared/assets/img/pricing/background.svg';

import {
  WrappText,
  Wrapper,
  WrappPremiumCards,
  Img,
  Title,
  Description,
  Enterprise,
  ContactBtn
} from './style';
import GetStarted from '../../components/GetStarted';
import ScrollToTop from '../../components/ScrollToTop';

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ScrollToTop />
      <WrappText>
        <Title fontSize="30px" upperCase fontWeight={500}>
          {t('pricing.1')}
        </Title>
        <Description>{t('pricing.2')}</Description>
      </WrappText>

      <WrappPremiumCards>
        <Premium
          price={19}
          text="Baby gamer"
          premiumTitles={[
            '1 game',
            '1 site',
            'Up to 50k impressions per month',
            'Real-time statistics',
            'Design customization',
            'Set coupons',
            'Image Upload',
            'Scheduling',
            'Mobile responsive'
          ]}
        />
        <Premium
          price={35}
          text="Junior gamer"
          premiumTitles={[
            '5 games',
            '5 sites',
            'Up to 150k impressions per month',
            'Real-time statistics',
            'Design customization',
            'Set coupons',
            'Image Upload',
            'Scheduling',
            'Mobile responsive'
          ]}
        />
        <Premium
          price={49}
          text="Experience gamer"
          premiumTitles={[
            '10 games',
            '10 sites',
            'Up to 500k impressions per month',
            'Real-time statistics',
            'Design customization',
            'Set coupons',
            'Image Upload',
            'Scheduling',
            'Personal assistant support',
            'Get all new features before everyone!',
            'Mobile responsive'
          ]}
        />
        <Premium
          price={199}
          text="Ultra-gamer"
          premiumTitles={[
            'Unlimited games',
            'Unlimited amount of sites site',
            'Unlimited amount of impressions per month',
            'Real-time statistics',
            'Design customization',
            'Set coupons',
            'Image Upload',
            'Scheduling',
            'Personal assistant support',
            'Get all new features before everyone!',
            'Mobile responsive'
          ]}
        />
      </WrappPremiumCards>
      <Enterprise>
        <Title>Enterprise</Title>
        <ContactBtn>Contact us</ContactBtn>
      </Enterprise>
      <Img src={background} alt="background" />
      <GetStarted />
    </Wrapper>
  );
};
export default Pricing;
