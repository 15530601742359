import React, { useState } from 'react';
import {
  MenuList,
  Backdrop,
  MenuToggle,
  Link,
  GlobalStyle,
  Wrapper
} from './StyledComponents';
import NavLinks from '../../shared/constants/navLinks';
import Languages from '../Languages';

const Drawer = ({ setDrawerLanguages }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Wrapper>
        <GlobalStyle toggle={toggle} />
        <MenuToggle
          open={toggle}
          onClick={() => setToggle(!toggle)}
          className={toggle ? 'fa fa-times' : 'fa fa-bars'}
        />
        <Languages setLanguages={setDrawerLanguages} />
        <MenuList open={toggle}>
          {NavLinks().map((item) => (
            <Link
              onClick={() => setToggle(!toggle)}
              activeClassName="navbar-active"
              key={item.link}
              to={`/${item.link}`}
            >
              {item.title}
            </Link>
          ))}
        </MenuList>
      </Wrapper>
      {toggle && <Backdrop onClick={() => setToggle(!toggle)} />}
    </>
  );
};

export default Drawer;
