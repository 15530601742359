import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-top: 220px;
  width: 72%;
  @media all and (max-width: 768px), (max-height: 415px) {
    padding-top: 120px;
  }
`;

export const Wrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 220px;
`;

export const PostImage = styled.img`
  height: auto;
  width: 100%;
`;

export const WrappText = styled.div`
  display: flex;
  max-width: 700px;
  padding: 0 5%;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin: 0 0 40px;
  color: #474747;
  font-weight: 500;
  font-size: 30px;
  @media (max-width: 470px) {
    margin: 0 0 20px;
    font-size: 17px;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 5% 0;
  font-size: 16px;
  color: #474747;
  @media (max-width: 470px) {
    font-size: 14px;
  }
`;

export const WrappBlogs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
  @media all and (max-width: 768px), (max-height: 415px) {
    padding-top: 120px;
  }
`;

export const Content = styled.div`
  margin: 50px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  @media all and (max-width: 768px), (max-height: 415px) {
    margin: 25px 0 0;
  }
`;

export const Article = styled.div`
  width: 75%;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  @media all and (max-width: 768px), (max-height: 415px) {
    width: 100%;
  }
`;

export const OtherPosts = styled.div`
  font-family: 'Montserrat', sans-serif;
  width: 20%;
  color: #000;
  @media all and (max-width: 768px), (max-height: 415px) {
    margin: 50px auto 0;
    width: 70%;
  }
`;

export const OtherBlogCard = styled.div`
  margin: 0 0 30px;
  width: 100%;
  font-size: 11px;
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 4px 4px 0 0;
`;

export const ImgWrapper = styled.div`
  margin: 0 0 18px;
  height: 300px;
  width: 100%;
  overflow: hidden;

  & ${Img} {
    transform: translateY(50%);
  }

  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const OtherTitle = styled.h4`
  font-size: 14px;
`;

export const ReadMore = styled(Link)`
  border: none;
  background: transparent;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  width: 100px;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  color: #474747;
  font-size: 12px;
`;

export const PostDescriptio = styled.h4`
  margin: 10px 0;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #474747;
`;

export const NavContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
`;

export const NavBtn = styled(Link)`
  margin: 25px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 30%;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(180deg, #32b1e8 0%, #4898dd 100%);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 20px;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  @media all and (max-width: 768px), (max-height: 415px) {
    width: 50%;
  }
`;
