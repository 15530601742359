import fr from '../assets/icons/flags/fr.svg';
import ru from '../assets/icons/flags/ru.svg';
import en from '../assets/icons/flags/us.svg';
import de from '../assets/icons/flags/de.svg';
import he from '../assets/icons/flags/il.svg';

const flags = {
  ru,
  fr,
  en,
  de,
  he
};

export default flags;
