import React from 'react';
// import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Description, Title, WrappBlogs, Wrapper, WrappText } from './style';
import BlogCard from './BlogCard';
import Posts from './posts';
import ScrollToTop from '../../components/ScrollToTop';

const Blog = () => {
  // const location = useLocation();
  // const pageAddress = location.pathname.split('/');
  // const pageNumber = +pageAddress[pageAddress.length - 1];

  const { t } = useTranslation();

  return (
    <Wrapper>
      <ScrollToTop />
      <WrappText>
        <Title size="30px" fontWeight={500}>
          {t('blog.1')}
        </Title>
        <Description>{t('blog.2')}</Description>
      </WrappText>
      <WrappBlogs>
        {Posts().map((post, i) => (
          <BlogCard
            key={i.toString()}
            img={post.img}
            link={`/blog/post/${i + 1}`}
            title={post.title}
            description={post.description}
          />
        ))}
      </WrappBlogs>

      {/* <Pagination>
        <PageBtn disabled active={pageNumber === 1} to="/blog/page/1">
          1
        </PageBtn>
      </Pagination> */}
    </Wrapper>
  );
};

export default Blog;
