import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  padding-bottom: 10px;
  @media (max-width: 425px) {
    font-size: 17px;
  }
`;

export const Description = styled.div`
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-weight: normal;
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Wrapper = styled.div`
  margin: 35px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10%;
  @media (max-width: 1024px) {
    min-height: 35vw;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    min-height: 140vw;
    padding: 0 10%;
  }
  @media (max-width: 320px) {
    min-height: 180vw;
  }
`;

export const Block = styled.div`
  width: 27%;
  @media (max-width: 425px) {
    width: 90%;
    margin-top: 40px;
  }
`;

export const Round = styled.div`
  width: 70px;
  height: 70px;
  margin-bottom: 5%;
  border-radius: 50px;
  background: linear-gradient(
    155.62deg,
    #ff55e1 -50.61%,
    #9b3db4 78.87%,
    #fd9946 224.06%
  );
`;

export const Img = styled.img`
  width: 50%;
  ${({ revers }) => {
    return revers ? 'margin-left: 5%' : 'margin-right: 5%';
  }};
  @media (max-width: 425px) {
    width: 90%;
    margin: 0;
  }
`;
