import amazon from '../assets/icons/amazon.svg';
import yahoo from '../assets/icons/yahoo.svg';
import ebay from '../assets/icons/ebay.svg';
import ebayProStores from '../assets/icons/ProStores-Logo-Ebay.svg';
import shopify from '../assets/icons/shopify.svg';
import vendi from '../assets/icons/vendi.svg';
import volusion from '../assets/icons/volusion.svg';
import zencart from '../assets/icons/zencart.svg';

const links = [
  {
    link: 'https://www.amazon.com/',
    src: amazon
  },
  {
    link: 'https://www.yahoo.com/',
    src: yahoo
  },
  {
    link: 'https://www.ebay.com/',
    src: ebay
  },
  {
    link: 'https://www.ebay.com/',
    src: ebayProStores
  },
  {
    link: 'https://www.shopify.com/',
    src: shopify
  },
  {
    link: 'https://vendiapp.com/',
    src: vendi
  },
  {
    link: 'https://www.volusion.com/',
    src: volusion
  },
  {
    link: 'https://www.zen-cart.com/',
    src: zencart
  }
];

export default links;
