import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledStartBtn from './StyledComponent';

const StartBtn = ({ width, background }) => {
  const { t } = useTranslation();
  return (
    <StyledStartBtn
      background={background}
      w={width}
      href="https://playthem.herokuapp.com/#/register"
    >
      {t('home.btnGetStarted')}
    </StyledStartBtn>
  );
};

export default StartBtn;
