import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

export const StylePreviewGames = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 100px;
  @media (max-width: 1335px) {
    gap: 80px 20px;
  }
  @media (max-width: 570px) {
    gap: 30px;
    padding-top: 20px;
  }
`;

export const Title = styled.div`
  margin-top: 130px;
  font-family: 'Montserrat', sans-serif;
  color: #474747;
  font-weight: 500;
  font-size: 30px;
  line-height: 70px;
  text-transform: capitalize;
  @media (max-width: 768px) {
    margin-top: 60px;
    font-size: 17px;
  }
`;
