import React from 'react';
import StartBtn from '../../components/Buttons/StartBtn';
import {
  Wrapper,
  Description,
  Img,
  Title,
  Block,
  WrappText,
  WrappTextBlock,
  StartWrapper,
  BackgroundImg,
  StartTextWrapper,
  Description2
} from './style';

import img from '../../shared/assets/img/platform/img.svg';
import background from '../../shared/assets/img/case-background.svg';

const UseCase = () => {
  return (
    <Wrapper>
      <WrappText>
        <Title size="30px" fontWeight={500}>
          PLATHEM Games
        </Title>
        <Description>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident
        </Description>
      </WrappText>
      <Block revers>
        <Img revers src={img} alt="any" />
        <WrappTextBlock>
          <Title size="30px">Some text</Title>
          <Description>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident
          </Description>
        </WrappTextBlock>
      </Block>
      <Block>
        <Img src={img} alt="any" />
        <WrappTextBlock>
          <Title size="30px">Some text</Title>
          <Description>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident
          </Description>
        </WrappTextBlock>
      </Block>

      <StartWrapper>
        <BackgroundImg src={background} alt="background" />

        <StartTextWrapper>
          <Title size="30px" color="#fff">
            Get started with 7 days trial
          </Title>
          <Description2>
            Playthem is online builder for pop-up widgets, it has all neсessary
            features and saves your time.
          </Description2>
          <StartBtn />
        </StartTextWrapper>
      </StartWrapper>
    </Wrapper>
  );
};

export default UseCase;
