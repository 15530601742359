import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from './pages/Main/Main';
import Pricing from './pages/Pricing/Pricing';
import Navbar from './components/Navbar';
import { AppWrapper } from './StyledComponents';
import Platform from './pages/Platform';
import UseCase from './pages/UseCase';
import Footer from './components/Footer';
import PageNotFound from './pages/PageNotFound';
import Blog from './pages/Blog';
import Post from './pages/Blog/Post';

const App = () => {
  const [lang, setLang] = useState('');

  const setLanguages = (lng) => {
    setLang(lng);
  };

  useEffect(() => {
    setLang(localStorage.getItem('lng'));
  }, []);

  return (
    <AppWrapper language={lang}>
      <Navbar setLanguages={setLanguages} />
      <Switch>
        <Route exact path="/" component={() => <Main languages={lang} />} />
        <Route exact path="/our-games" component={Main} />
        <Route
          exact
          path="/platform"
          component={() => <Platform languages={lang} />}
        />
        <Route exact path="/use-case" component={UseCase} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/blog/page/:page" component={Blog} />
        <Route exact path="/blog/post/:id" component={Post} />
        <Route exact path="/log-in" component={Main} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </AppWrapper>
  );
};

export default App;
