import React from 'react';
import { useTranslation } from 'react-i18next';
import img from '../../shared/assets/img/platform/img.svg';
import {
  Wrapper,
  Title,
  Description,
  WrappBlock4,
  Block4,
  ImgBlock4,
  WrappTitle
} from './style';

const BlogInsights = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WrappTitle>
        <Title size="36px">{t('home.21')}</Title>
      </WrappTitle>

      <WrappBlock4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('home.22')}</Title>
          <Description>{t('home.23')}</Description>
        </Block4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('home.24')}</Title>
          <Description>{t('home.25')}</Description>
        </Block4>
        <Block4>
          <ImgBlock4 src={img} alt="any" />
          <Title size="24px">{t('home.26')}</Title>
          <Description>{t('home.27')}</Description>
        </Block4>
      </WrappBlock4>
    </Wrapper>
  );
};

export default BlogInsights;
