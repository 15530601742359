import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50vh;
  background: linear-gradient(#f8f8f8, 98%, white);
  @media (max-width: 1920px) {
    padding-bottom: 55vh;
  }
  @media (max-width: 1440px) {
    padding-bottom: 50vh;
  }
  @media (max-width: 1024px) {
    padding-bottom: 25vh;
  }
  @media (max-width: 768px) {
    padding-bottom: 5vh;
  }
`;

const Logo = styled.img`
  margin: 15px 0;
  max-width: 200px;
  @media (max-width: 1024px) {
    max-width: 150px;
    margin: 15px 0;
  }
  @media (max-width: 425px) {
    max-width: 80px;
    margin: 5px 0;
  }
`;

const ImgBgr = styled.img`
  ${({ languages }) =>
    languages === 'he' ? 'left:0; transform: scaleX(-1);' : 'right:0'};
  padding-top: 120px;
  position: absolute;
  max-width: 800px;
  @media (max-width: 1024px) {
    padding-top: 97px;
  }
  @media (max-width: 768px) {
    max-width: 400px;
  }
  @media (max-width: 425px) {
    padding-top: 49px;
  }
`;

export { Wrapper, Logo, ImgBgr };
