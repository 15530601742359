import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavList, Wrapper, Link, LogIn, Container } from './StyledComponent';
import StartBtn from '../Buttons/StartBtn';
import logo from '../../shared/assets/img/logo.svg';
import { Logo } from '../Header/StyledComponets';
import Languages from '../Languages';
import NavLinks from '../../shared/constants/navLinks';
import Drawer from '../Drawer';
// import Drawer from '../Drawer';

const Navbar = ({ setLanguages }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <Logo src={logo} alt="logo" />
        </Link>
        <NavList>
          {NavLinks().map((item) => (
            <Link
              activeClassName="navbar-active"
              key={item.link}
              to={`/${item.link}`}
            >
              {item.title}
            </Link>
          ))}

          <LogIn href="https://playthem.herokuapp.com/#/login">
            {t('home.btnHeader_login')}
          </LogIn>

          <StartBtn />
          <Languages setLanguages={setLanguages} />
        </NavList>
        <Drawer setDrawerLanguages={setLanguages} />
      </Container>
    </Wrapper>
  );
};

export default Navbar;
