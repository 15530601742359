import React from 'react';
import Header from '../../components/Header/Header';
import Logos from '../../components/Logos';
import Games from '../../components/Games';
import GetStarted from '../../components/GetStarted';
import UserReviews from '../../components/UserReviews';
import BlogInsights from '../../components/BlogInsights';
import ScrollToTop from '../../components/ScrollToTop';

const Main = ({ languages }) => {
  return (
    <>
      <ScrollToTop />
      <Header languages={languages} />
      <Logos />
      <Games />
      <GetStarted />
      <UserReviews />
      <BlogInsights />
    </>
  );
};

export default Main;
