import { useTranslation } from 'react-i18next';
import bgr1 from '../../shared/assets/img/blog/bgr3.png';
import bgr2 from '../../shared/assets/img/blog/bgr4.png';
import bgr3 from '../../shared/assets/img/blog/bgr5.png';
import bgr4 from '../../shared/assets/img/blog/bgr6.png';

const Posts = () => {
  const { t } = useTranslation();

  return [
    {
      img: bgr1,
      title: t('blog.3'),
      description: t('blog.4'),
      url: '/blog/post/1',
      post: (
        <p>
          While the term has picked up a lot when it comes to customer
          engagement, online marketers and business owners believe it is a
          tactic meant only for mobile apps. But what it simply means is putting
          typical elements of a game like a point scoring, winning and
          completing, to areas where a customer touch base is more likely. Take,
          for instance, your online store! Gamification in marketing is targeted
          at driving higher customer engagement in times when the attention
          spans are reducing by the day. It follows the simple principle that
          when a customer is more engaged with your business, the higher are the
          conversions, sales, and revenue for your online- store. How to
          implement gamification to increase online-sales? You can increase
          online-sales with gamification in a number of ways, across different
          channels – starting right from your customer emails, social media, and
          even your website. A successful gamification strategy includes: 1.A
          chance for the online shopper to win something (coupons, rewards,
          in-store cash, etc) 2.Nudges the shopper should to take a small action
          (spin a wheel, choose a card, add to wishlist, etc) 3.Gives the
          shopper a trigger to complete the purchase Gamification to increase
          online- sales
          <br />
          <br />
          <b>1. Gamified email popups</b>
          <br />
          Every online- store has a pop up implemented to capture their
          visitors’ attention and promote ongoing deals. But why use the usual
          approach of offering a discount in turn for an email address? Give
          your popup strategy a twist by gamifying the experience of getting a
          coupon in lieu of email addresses and make it all the more engaging.
          Swap the usual email popups like these: With smart gamified popups
          using PLAYthem, like: And see how your online- store visitors start to
          spend more time on your site, engage with your brand and feel more
          compelled to avail offers in purchases.
          <br />
          <br />
          <b>2. Gamified rewards</b>
          <br />
          Who says online-stores can only reward customers for making purchases.
          Instead of a transactional relationship, gamify rewards by breaking
          them down for completing smaller actions that help you understand the
          shopper better for optimizing marketing campaigns. For instance, offer
          rewards for completing their account information, sharing their
          purchases on social media, following your brand on social media,
          creating wishlists or even simply celebrating their birthday with you!
          <br />
          <br />
          <b>3. Gamified web push notifications</b>
          <br />
          No matter how good a deal you’re offering, online shoppers have a
          tendency to explore the options they have. They’ll check out other
          online- stores and even browse through affiliate and coupon sites to
          be able to bag a better deal. Now instead of waiting for them to come
          back to your store, increase online- sales by sending in a quick web
          push notification with PushOwl that re-engages them. Instead of the
          usual ‘offer a discount to win a customer’, gamify the message – nudge
          them to complete an interesting action to win a coupon.
          <br />
          <br />
          <b>4. Gamified emails</b>
          <br />
          Emails are still one of the most effective strategies for online
          stores to engage their customers and subscribers. But most online-
          stores wouldn’t go beyond the usually curated newsletters and
          transactional emails. While transactional emails still have higher
          conversion rates, the open rates on newsletters are dropping by the
          day. So instead, add gamifying elements to your emails. Along with
          product recommendations, add a message about how they could win an
          additional discount on the purchase for completing a small action –
          reviewing a previous purchase, inviting their friends, etc.
          <br />
          <br />
          <b>5. Gamified social media</b>
          <br />
          Social media is often just used for showcasing product images or
          sharing lookbooks and videos. But imagine your entire feed is filled
          with only product images from all the brands you’ve been following.
          You not only will start losing interest in the posts, but also not be
          able to remember which product was on which online- store. Use social
          media to also gamify the shopper’s interaction with your brand. For
          instance, run a contest that nudges them to share their experience or
          pictures of the previous purchase. Offer them an additional discount
          that is hard to say no to when they win. You won’t just boost your
          customer engagement rate, but also end up with unique user-generated
          content that increases the word of mouth promotion for your brand. Is
          gamification for your online- store? On the off chance to increase
          online- sales in a competitive market where it is becoming more about
          who has higher spending power, the answer is YES. Gamification targets
          improving a shopper’s journey with your online- store by making
          interactions fun and adding value to the interaction at the same time.
          The engagement leaves a positive impression on the shopper, helping
          you build a better and stronger brand reputation, resulting in
          customer loyalty. And as studies suggest, even 12-15% of loyal
          customers, can represent 55-70% of your online- store’s sales.
        </p>
      )
    },
    {
      img: bgr2,
      title: t('blog.5'),
      description: t('blog.6'),
      url: '/blog/post/2',
      post: (
        <p>
          The structure of a push notification is quite simple: Title with a
          maximum length of 65 characters. A picture of 192×192, 360×240 pixels,
          depending on the service used.
          <br />
          <b>
            The main text of the message, up to 240 characters long.
            <br />A link to the site.
            <br />A CTA button.
            <br />
          </b>
          <br />
          Push notifications come in different forms. Let&apos;s talk about
          them. Web push notifications or browser notifications are messages
          that the user receives on the desktop of their PC in the lower
          right-hand corner. Email pop-up widget also is helpfully used in
          sales. How to choose the content and frequency of mailings based on
          the specifics of the business and target audience 1. For notifications
          to work, they must be useful for the subscriber and be interesting to
          them. Start creating a push notification campaign by analyzing the
          interests of the target audience and segmenting it. For example, look
          into the target audience’s gender, age, location, and interests. The
          combination of gender, age, and interests will allow you to speak the
          same language with your customers and offer them relevant products. A
          few people at the age of 18 will be interested in the offer on false
          teeth. Likewise, it is difficult to imagine a grandfather who at the
          age of 70 will be interested in braces. Given the location of a
          person, you can send them promotions and offers with the name of their
          settlement. If the online store has retail outlets, you can inform
          subscribers about the beginning of a sale, promotions, and discounts,
          or about new products at a point of sale near their home. You can also
          remind them of the forgotten products in the basket. 2. If you have a
          youth audience, feel free to use slang. In fact, this technique can be
          applied to all audiences. If you speak with the client in their
          language, it inspires confidence. 3. Use triggers. A trigger is
          something that encourages the subscriber to perform that targeted
          action. It can be:
          <br />
          <br />
          <b>Benefit</b> – stimulates to make a purchase
          <br />
          <br />
          <b>Desire to be part of the whole</b> – a person wants to be a part of
          the world of cool, modern, successful people, so they need to get your
          product
          <br />
          <br />
          <b>Open-loop</b> – intrigue makes a person go to the site and find out
          the end of the message
          <br />
          <br />
          <b>Scarcity</b> – stimulates to buy now, while the goods are in stock
          <br />
          <br />
          <b>Exclusivity</b> – an offer not for everyone but only for the elite
          (for example, Beats headphones for those who appreciate real sound)
          <br />
          <br />
          <b>Conspiracy</b> – this offer is personal for a specific subscriber
          and no longer for anyone else (Shh, just for you today a 40% discount
          on everything) Every newsletter should have a clear purpose. Based on
          the goal, content and graphic material is formed.
        </p>
      )
    },
    {
      img: bgr3,
      title: t('blog.7'),
      description: t('blog.8'),
      url: '/blog/post/3',
      post: (
        <p>
          If you are doing a promotional email, the headline should be about
          customer benefits. For example, BEST DEAL! NEW DESIGNER SHOES ONLY
          $849.90. If reporting a discount, show the old price crossed out. So
          the user can understand the benefits of the purchase. You can also
          specify the amount of savings. Furthermore, you need to strengthen the
          desire to buy in the text of the message. Write down the strongest
          product benefits. After reading the information, the subscriber should
          think that they would be a fool if they did not take advantage of the
          offer. To enhance the effect, use a timed offer and add a call to
          action button. Thanks to this, the user will immediately go to the
          site, and not postpone it for later or forget. Purpose of sending web
          push notifications for e-commerce
          <br />
          <br />
          <b>
            Informing about promotions and discounts
            <br />
          </b>
          ewsletter of the company, image content Informing about new products A
          reminder of a forgotten item in the cart Sending out useful tips for
          using the company’s products Maximum traffic to the site If you want
          to inform users about new products, indicate this in the title. It is
          better to use stylish text in push notifications about new products
          than a selling one. In newsletters, also announce the usefulness of
          information for subscribers. Frequency of mailings. For e-commerce
          sites, you need to do mailings as new promotions and discounts appear.
          One notification should equal one share. You don’t need to send more
          than 1 notification per day. Ideally, test different mailing schedules
          and see which option has the least unsubscriptions. You can use
          analytics to select a schedule and see which days you have the lowest
          traffic to the site and create push notifications these days in order
          to activate clients.
          <br />
          <br />
          <b>
            Time and days of mailings.
            <br />
          </b>
          In order to find the most optimal time, it is necessary to conduct
          tests. Then you can estimate what day of the week and time of day you
          have the maximum number of views of push notifications. But while
          there are no statistics yet, analyze the target customer. What time
          are the maximum traffic on the site and the most conversions? Plan
          your first newsletter during this period. If subscribers live in
          different time zones, consider this when choosing when to send the
          notification. Segment clients by geo principle and create a separate
          mailing list for each time zone. It is unlikely that the user will
          click on the notification that came to them at an unreasonable time.
        </p>
      )
    },
    {
      img: bgr4,
      title: 'Greatest ways to use discounts to increase online-sales in 2021',
      description:
        'Today, let’s step into our own shoes as an online shopper. Think about the last time you purchased a product similar to the one you have. Then tell us if you bought both the products from the same store. There’s a high chance that your answer is no.',
      url: '/blog/post/4',
      post: (
        <p>
          Today, let’s step into our own shoes as an online shopper. Think about
          the last time you purchased a product similar to the one you have.
          Then tell us if you bought both the products from the same store.
          There’s a high chance that your answer is no. Ways to increase
          online-sales with offers !
          <br />
          <br />
          <b>
            1. Give out first-time and welcome discounts
            <br />
          </b>
          Most shoppers don’t make a purchase on their first visit to a store.
          They’re not sure about the brand and the quality of the products, or
          if they are worth the value. Sweeten the deal for them and make a
          lasting first impression with a welcome offer or a first-purchase
          discount.
          <br />
          <br />
          <b>
            2. Offer weekly/monthly discounts
            <br />
          </b>
          Identify popular products or product ranges and offer a discount on
          them periodically. When the shoppers see their interests are being
          noticed, you’ll have them coming back every time.
          <br />
          <br />
          <b>
            3. Recover abandoned carts with discounts
            <br />
          </b>
          Make a special offer to recover abandoned carts. Offer an additional,
          time-sensitive discount or free shipping. But remember to send them
          the offer in less than 24 hours of the abandonment. You don’t want to
          lose their interest!
          <br />
          <br />
          <b>
            4. Turn actions into incentives
            <br />
          </b>
          No amount of data on the shopper is ever enough. But 90% of your
          consumers expect a personalized experience with the brand. To kill two
          birds with one stone, why not offer discounts to shoppers for
          completing tasks that benefit your marketing strategy?
          <br />
          <br />
          <b>
            5. Holiday and seasonal offers
            <br />
          </b>
          Based on your target market and who your customers are, identify the
          holidays and seasons they acknowledge. Join in their celebrations and
          preparations by offering them a custom discount on products relevant
          to those days. You taking note of these little things will have
          shoppers come back for more.
          <br />
          <br />
          <b>
            6. Make an email subscription offer
            <br />
          </b>
          So many of your online shoppers are afraid of being spammed with
          irrelevant promotions. Pretty understandable, right? That’s where
          maybe you could make them an offer they cannot say no to. Offer them a
          special discount in turn of their email address – super simple and
          straightforward.
          <br />
          <br />
          <b>
            7. Reward referrals with discounts
            <br />
          </b>
          92% of consumers trust recommendations from friends and family. But
          what do the friends and family get for making those recommendations –
          from the business side. More often than not, absolutely nothing. If
          you want to keep these customers encouraged to actively promote your
          brand, keep them motivated. The best way to do so is to offer them a
          discount for successfully bringing you a referred customer. You could
          also further sweeten the deal for your existing and new customers by
          offering them both special discounts. You got to do what it takes to
          keep the chain of word-of-mouth promotion going!
          <br />
          <br />
          <b>
            8. Exit intent discount
            <br />
          </b>
          As we all know, once the shopper leaves the site, there’s a high
          chance they don’t come back. That’s where an exit-intent discount
          comes into play. Grab the leaving shopper’s attention with a discount
          they can’t say no to. Take it as your last sales pitch to them and
          nail it with the right popup copy.
          <br />
          <br />
          <b>
            9. Celebrate customer milestones with offers
            <br />
          </b>
          You’re already recording when a shopper signs up on your store or when
          they make the first purchase. Use that data to identify every
          customer’s milestones and automate a marketing campaign that reaches
          out to them. Thank them for their continual support and offer them a
          sweet discount as a gift, that will surely make them feel worthy.
          <br />
          <br />
          <b>
            10. Set up minimal purchase discounts
            <br />
          </b>
          This tactic is usually used for upselling to encourage shoppers to add
          more products to the cart and increase their average order size.
          Telling the shopper that they can avail a free discount on a said cart
          value and above, you can engage them on the store a little longer. It
          will also help you get other products discovered as the shopper moves
          across ranges to find something that helps them reach the minimum
          order value. Should you use offers to boost customer loyalty?
          Absolutely. Offers are not just easy and quick to implement, but also
          easy to track. They help brands boost their conversions, sales,
          revenue and most importantly, customer loyalty. And that’s what your
          store needs to grow in this competitive market – customers who can
          vouch for them!
        </p>
      )
    }
  ];
};

export default Posts;
