import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #474747;
  font-weight: 500;
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 10px;
  @media (max-width: 470px) {
    font-size: 17px;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 50px 0;
  font-size: 16px;
  color: #474747;
  @media (max-width: 470px) {
    font-size: 14px;
    margin: 3% 0;
  }
`;

export const Img = styled.img`
  width: 50%;
  ${({ revers }) => {
    return revers ? 'margin-left: 5%' : 'margin-right: 5%';
  }};
  @media (max-width: 425px) {
    width: 90%;
    margin: 0;
  }
`;

export const WrappBlock4 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 10%;
  @media (max-width: 425px) {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const Block4 = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }
`;

export const ImgBlock4 = styled.img`
  width: 100%;
  margin-bottom: 5%;
`;

export const WrappTitle = styled.div`
  margin-top: 5%;
  @media (max-width: 425px) {
    margin-top: 15%;
  }
`;
