import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  @media (max-width: 768px) {
    padding-top: 75px;
  }
`;

export const WrappText = styled.div`
  display: flex;
  max-width: 700px;
  padding: 0 10%;
  text-align: center;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 470px) {
    height: 100px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 30px;
  @media (max-width: 470px) {
    font-size: 17px;
    text-align: start;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;

  font-size: 16px;
  color: #474747;
  @media (max-width: 470px) {
    font-size: 14px;
    margin-top: 2%;
    text-align: start;
  }
`;

export const WrappPremiumCards = styled.div`
  display: flex;
  width: 100%;
  min-width: 750px;
  margin-top: 5%;
  justify-content: space-evenly;
  @media (max-width: 825px) {
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    min-width: 100%;
  }
`;

export const Img = styled.img`
  position: absolute;
  min-width: 920px;
  z-index: -1;
  width: 70%;
  top: 50px;
  @media (max-width: 825px) {
    display: none;
  }
`;

export const ContactBtn = styled.button`
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.5s;
  white-space: nowrap;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 30px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 2px;
  background: linear-gradient(180deg, #32b1e8 0%, #4898dd 100%);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);

  :hover {
    cursor: pointer;
  }
`;

export const Enterprise = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  padding: 4%;
  margin: 15px 0;
  cursor: default;
  transition-duration: 0.5s;
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  :hover {
    background: #6a4fe4;
    ${ContactBtn} {
      background: #6a4fe4;
      border: 1px solid #fff;
      border-radius: 5px;
    }
    ${Title} {
      color: #fff;
    }
  }

  @media (max-width: 1440px) {
    min-width: 210px;
  }
  @media (max-width: 1024px) {
    min-width: 180px;
    padding: 25px;
  }
  @media (max-width: 768px) {
    width: 66%;
    margin: 10%;
    max-width: none;
  }
`;
