import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  position: fixed;
  width: 85%;
  background: #f8f8f8;
  z-index: 2;
  padding: 0 5% 0 10%;
  @media (max-width: 1024px) {
    padding: 0 0 0 10%;
    width: 90%;
  }
  @media (max-width: 768px) {
    padding: 0 10%;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const NavList = styled.ul`
  @media (max-width: 1024px) {
    display: none;
  }
  display: flex;
  width: 60%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

export const Link = styled(NavLink)`
  margin: 0 3px;
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  color: #474747;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  &:hover {
    cursor: pointer;
  }
`;

export const LogIn = styled.a`
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  color: #474747;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  &:hover {
    cursor: pointer;
  }
`;
