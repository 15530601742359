import styled from 'styled-components';

export const Text = styled.div`
  text-align: center;
  padding: 20px 0 10px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #474747;
`;

export const Btn = styled.div`
  ${({ active }) => {
    return active
      ? 'background: linear-gradient(180deg, #32b1e8 0%, #4898dd 100%); color: #ffffff;'
      : '';
  }};
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.3s;
  font-size: 15px;
  padding: 10px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  color: #fff;
  background: linear-gradient(180deg, #32b1e8 0%, #4898dd 100%);
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  min-height: 130px;
  width: 264px;
  background: #fff;
  box-shadow: 0 4px 20px rgb(106 79 228 / 39%);
  border-radius: 10px;
  transition: 0.3s all;

  &:hover {
    transition: 0.3s all;
    background: #6a4fe4;

    & ${Text} {
      color: #fff;
    }

    & ${Btn} {
      color: #6a4fe4;
      background: #fff;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 20px;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  max-width: 700px;
  padding: 30px 0;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    text-align: start;
    padding: 3% 0;
  }
`;

export const Img = styled.img`
  margin: -60px 0 0;
  width: 184px;
  @media (max-width: 768px) {
    margin: 20px 0 0;
    width: 50%;
  }
`;
