import styled from 'styled-components';

export const LeftBgTrial = styled.img`
  position: absolute;
  max-width: 50%;
  left: 0;
  @media (max-width: 768px) {
    top: -30%;
    height: 100%;
  }
`;
export const RightBgTrial = styled.img`
  position: absolute;
  max-width: 50%;
  right: 0;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 60px 10%;
  height: 25vw;
  max-height: 300px;
  background: #6a4fe4;
  width: 100%;
  margin-top: 5%;
  overflow: hidden;
  margin-bottom: ${({ mb }) => mb || '0px'};
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    max-height: max-content;
  }
  @media (max-width: 425px) {
    padding: 10%;
  }
  @media (max-width: 350px) {
    padding: 12%;
  }
  @media (max-width: 320px) {
    padding: 10%;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const WrappDescrtion = styled.div`
  gap: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1292px;
  @media (max-width: 768px) {
    gap: 30px;
    margin: 30px 0 0;
    flex-wrap: wrap;
    width: 90%;
  }
`;

export const Description = styled.div`
  position: relative;
  width: 60%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #fff;
  @media (max-width: 425px) {
    width: 100%;
    font-size: 14px;
  }
`;

export const TrialBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 10%;
  min-width: 130px;
  height: 42px;
  font-family: 'Montserrat', sans-serif;
  padding: 0 18px;
  border: 2px solid #ffffff;
  border-radius: 2px;
  color: #fff;
  background-color: #6a4fe4;
  z-index: 1;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 426px) {
    font-size: 15px;
    height: 30px;
  }
`;

export const WrappTitle = styled.div`
  width: 100%;
  z-index: 1;
`;
