import React from 'react';
import { useTranslation } from 'react-i18next';
import left from '../../shared/assets/img/pricing/left.svg';
import right from '../../shared/assets/img/pricing/right.svg';
import {
  LeftBgTrial,
  RightBgTrial,
  Wrapper,
  Title,
  WrappDescrtion,
  Description,
  TrialBtn,
  WrappTitle
} from './style';

const GetStarted = ({ marginBottom }) => {
  const { t } = useTranslation();
  return (
    <Wrapper mb={marginBottom}>
      <LeftBgTrial src={left} alt="left" />
      <RightBgTrial src={right} alt="right" />
      <WrappTitle>
        <Title>{t('home.13')}</Title>
      </WrappTitle>
      <WrappDescrtion>
        <Description>{t('home.14')}</Description>
        <TrialBtn>{t('home.btnGetStarted')}</TrialBtn>
      </WrappDescrtion>
    </Wrapper>
  );
};

export default GetStarted;
