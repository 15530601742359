import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logoFooter from '../../shared/assets/icons/logo-footer.svg';
import logoFooterMini from '../../shared/assets/icons/logo-footer-mini.svg';
import background from '../../shared/assets/img/footer.svg';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
`;

export const LinkFooter = styled.div`
  min-width: 144px;
  color: #37abe6;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 16px;
  @media (max-width: 426px) {
    min-width: auto;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  width: 100%;
  background: #fff url(${background}) no-repeat;
  bacground: contain;
  align-items: center;
  justify-content: space-evenly;
  @media all and (max-width: 768px) {
    height: 80px;
  }
`;

export const NavList = styled.ul`
  @media (max-width: 825px) {
    display: none;
  }
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  width: 650px;
`;

export const Link = styled(NavLink)`
  margin: 0 3px;
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  color: #474747;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const Img = styled.div`
  width: 75px;
  height: 75px;
  background: url(${logoFooter});
  @media (max-width: 425px) {
    width: 45px;
    height: 45px;
    background: url(${logoFooterMini});
  }
`;

export const Copyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  background: #efefef;
  color: #474747;
`;

export default Wrapper;
