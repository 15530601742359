import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: {
          btnGetStarted: 'Get Started Now',
          btnWatch: 'Watch Video',
          btnHeader_login: 'Log In',
          btnHeader_blog: 'Blog',
          btnHeader_pricing: 'Pricing',
          btnHeader_software: 'Software Overview',
          btnHeader_games: 'Playthem Games',
          startTitle: 'Play them, you sell!',
          startDescription:
            'Are you looking for eCommerce popup examples that are used by companies just like yours to increase profits?\n' +
            '        you can use the playthem widgets to start building high-converting popups for your company right now!',
          intergrate: 'Integrate playthem with your favorite eCommerce stack',
          7: 'Engage clients with your self-branded game and give them way to get a gift. Games is a great way to extend the time visitors spend on your website and convert users into customers by giving them a reward at the end of the game.',
          8: 'Roulette',
          9: 'Memory',
          10: '',
          11: '',
          12: 'Preview game',
          13: 'You want to increase your profits? start improving  website right now!',
          14: 'If each customer buys more, you will spend less resources selling one item. Spend part of the funds on stimulating large orders. offer customers a discount when ordering a specific amount, gift, or free delivery',
          15: 'James Trevor',
          16: "If you are serious about promoting your store and re-selling back to potentialcustomers, you need this add on.We've generated nearly 6000 email address in 5 months, just from running it on the store!",
          17: 'Dream-team shop',
          18: 'Great customer service. The popups have been very helpful in terms of upsell. They are very responsive. They even jumped in on a Sunday to help resolve an issue. Im very pleased with this application and their level of professionalism.',
          19: 'Meth Cohn',
          20: 'We like your product. Memory - is a sensible game. We have +20% leads from One monts. Waiting for new games',
          21: 'Insights from our blog',
          22: 'Are Pop-Up Ads dangerous?',
          23: "Particularly nasty pop-ups are used to sell fake anti-virus programs, which are sometimes called 'scareware'. The pop-ups pretend to find viruses on your PC and - after you have paid up - make like to remove it. In fact, these programs are malware and may install more malware.",
          24: 'Why pop-ups are effective?',
          25: 'Simply put, popups are effective for the same reason landing pages are effective: they eliminate distractions, provide the user with a last-chance offer and distill the choice down to a simple yes-or-no answer.',
          26: 'What is a pop-up in marketing?',
          27: 'In their simplest form, pop-up events are temporary retail spaces that give companies the opportunity to sell their products in an environment completely designed and controlled by them.'
        },
        software: {
          1: '3 easy steps to increase customer engagement',
          2: 'The  simple steps and you get a personal source of  traffic for your store! Follow our simple instructions and you`ll get the results in just 20 minutes.',
          3: '1st step - register on site',
          4: "If you have a Google account, you can use it to sign in to Playthem. This means you've got one less password to remember.  other option is Use the Registration Form",
          5: '2nd step - Customize game ',
          6: 'You don’t need programming skills to build a game. Use our range of tools and templates to design a branded mini-game. Games are designed to function on all mobile devices. Get set up in just 10 minutes!\n',
          7: '3rd step - pay and use',
          8: 'With PayPal, you can use one of the following payment methods: Your bank account. Your Visa, MasterCard, Discover or American Express card. ',
          9: 'Try it right now!',
          10: 'Playthem  is online builder for pop-up widgets, it has all neсessary features and saves your time. ',
          11: 'Customers',
          12: 'Over 70% of visitors who abandon your website will never returnLearn how to unlock the highest conversion revenue from each of your website visitors!',
          13: 'Gaming',
          14: 'That is means, using game approaches and processes to engage people. These processes focus on human emotions.',
          15: 'Profit',
          16: 'The game advertising advantage is a wider reach of the target audience. The larger the coverage, the more likely to attract new customers.',
          17: 'You want to increase your profits? Start improving  website right now!',
          18: 'If each customer buys more, you will spend less resources selling one item. Spend part of the funds on stimulating large orders. offer customers a discount when ordering a specific amount, gift, or free delivery',
          20: 'Insights from our blog',
          21: 'Gamification in Sales',
          22: 'The number of online-stores are growing by the day. A typical online shopper has at least five stores to choose from while making a purchase.[ If the competition wasn’t already enough, each of these stores are also using the same tactics to reach their target customers. ',
          23: 'Increase online-sales ',
          24: 'Today, let’s step into our own shoes as an online shopper. Think about the last time you purchased a product similar to the one you have. Then tell us if you bought both the products from the same store. There’s a high chance that your answer is no.',
          25: 'Push for e-commerce',
          26: 'A gaming-push notification is a short, small, pop-up web notification that appears on the screen of a computer, smartphone, or laptop. It chance to inform for users about application updates, discounts on products, an application that may interest them, etc.'
        },
        pricing: {
          1: 'Pop Up buildings price Straightforward, affordable pricing',
          2: 'At the end of your trial, or when you decide to launch your store, you will need to pick a plan and enter your payment details.'
        },
        blog: {
          1: 'Blog',
          2: 'Playthem is a smart e-Commerce app that enables you to create gamified popups to grow your email list, increase sales and revenue with better customer engagement.',
          3: 'Gamification in Sales - Gamification for Everyone',
          4: 'The number of online-stores are growing by the day. A typical online shopper has at least five stores to choose from while making a purchase. If the competition wasn’t already enough, each of these stores are also using the same tactics to reach their target customers. Be it social media, email marketing or even advertising campaigns, everyone is doing everything. So what is the one thing you can do to make your strategy stand out and increase Shopify sales?',
          5: 'Greatest ways to use discounts to increase online-sales in 2021',
          6: 'Today, let’s step into our own shoes as an online shopper. Think about the last time you purchased a product similar to the one you have. Then tell us if you bought both the products from the same store. There’s a high chance that your answer is no.',
          7: 'Gaming push notifications, what they are and why you need them',
          8:
            'If you want to inform users about new products, indicate this in the title. It is better to use stylish text in push notifications about new products than a selling one.' +
            'In newsletters, also announce the usefulness of information for subscribers.'
        }
      }
    },
    he: {
      translation: {
        home: {
          startTitle: 'אנחנו עוזרים לכם להגדיל את המכירות ב 5 דקות!',
          startDescription:
            'אני אפטר מדבריך לפחות מהגוף' +
            'נהגתי נבלה, לא אמצא נשמה' +
            'זה כואב, אוי, איך זה כואב והדם רותח' +
            'והבת כבר לא ישנה, פחות מילים, יותר פעולה',
          btnGetStarted: 'התחל עכשיו!',
          btnWatch: 'לסרטון שלנו',
          btnHeader_login: 'התחברות',
          btnHeader_blog: 'בלוג',
          btnHeader_pricing: 'מחירון',
          btnHeader_software: 'צור קשר',
          btnHeader_games: 'צור קשר',
          intergrate: '!המערכת של Playthem מתממשקת עם כל הטפלורמות באינטרנט',
          7: 'Engage clients with your self-branded game and give them way to get a gift. Games is a great way to extend the time visitors spend on your website and convert users into customers by giving them a reward at the end of the game.',
          8: 'רולטה',
          9: 'משחק זיכרון',
          10: 'המשחקים של פליידם',
          11: 'המשחקים של פליידם',
          12: 'לכל המשחקים שלנו',
          13: 'מעוניין להגדיל את כמות המכירות באתר שלך? לקבל מידע על הלקוחות? להגדיל רשימות תפוצה? אתה במקום הנכון!',
          14: 'If each customer buys more, you will spend less resources selling one item. Spend part of the funds on stimulating large orders. offer customers a discount when ordering a specific amount, gift, or free delivery',
          15: '',
          16: '',
          17: '',
          18: '',
          19: '',
          20: '',
          21: 'Insights from our blog',
          22: 'Are Pop-Up Ads dangerous?',
          23: "Particularly nasty pop-ups are used to sell fake anti-virus programs, which are sometimes called 'scareware'. The pop-ups pretend to find viruses on your PC and - after you have paid up - make like to remove it. In fact, these programs are malware and may install more malware.",
          24: 'Why pop-ups are effective?',
          25: 'Simply put, popups are effective for the same reason landing pages are effective: they eliminate distractions, provide the user with a last-chance offer and distill the choice down to a simple yes-or-no answer.',
          26: 'What is a pop-up in marketing?',
          27: 'In their simplest form, pop-up events are temporary retail spaces that give companies the opportunity to sell their products in an environment completely designed and controlled by them.'
        },
        software: {
          1: '3 easy steps to increase customer engagement',
          2: 'The  simple steps and you get a personal source of  traffic for your store! Follow our simple instructions and you`ll get the results in just 20 minutes.',
          3: '1st step - register on site',
          4: "If you have a Google account, you can use it to sign in to Playthem. This means you've got one less password to remember.  other option is Use the Registration Form",
          5: '2nd step - Customize game ',
          6: 'You don’t need programming skills to build a game. Use our range of tools and templates to design a branded mini-game. Games are designed to function on all mobile devices. Get set up in just 10 minutes!\n',
          7: '3rd step - pay and use',
          8: 'With PayPal, you can use one of the following payment methods: Your bank account. Your Visa, MasterCard, Discover or American Express card. ',
          9: 'Try it right now!',
          10: 'Playthem  is online builder for pop-up widgets, it has all neсessary features and saves your time. ',
          11: 'Customers',
          12: 'Over 70% of visitors who abandon your website will never returnLearn how to unlock the highest conversion revenue from each of your website visitors!',
          13: 'Gaming',
          14: 'That is means, using game approaches and processes to engage people. These processes focus on human emotions.',
          15: 'Profit',
          16: 'The game advertising advantage is a wider reach of the target audience. The larger the coverage, the more likely to attract new customers.',
          17: 'You want to increase your profits? Start improving  website right now!',
          18: 'If each customer buys more, you will spend less resources selling one item. Spend part of the funds on stimulating large orders. offer customers a discount when ordering a specific amount, gift, or free delivery',
          20: 'Insights from our blog',
          21: 'Gamification in Sales',
          22: 'The number of online-stores are growing by the day. A typical online shopper has at least five stores to choose from while making a purchase.[ If the competition wasn’t already enough, each of these stores are also using the same tactics to reach their target customers. ',
          23: 'Increase online-sales ',
          24: 'Today, let’s step into our own shoes as an online shopper. Think about the last time you purchased a product similar to the one you have. Then tell us if you bought both the products from the same store. There’s a high chance that your answer is no.',
          25: 'Push for e-commerce',
          26: 'A gaming-push notification is a short, small, pop-up web notification that appears on the screen of a computer, smartphone, or laptop. It chance to inform for users about application updates, discounts on products, an application that may interest them, etc.'
        },
        pricing: {
          1: 'Pop Up buildings price Straightforward, affordable pricing',
          2: 'At the end of your trial, or when you decide to launch your store, you will need to pick a plan and enter your payment details.'
        },
        blog: {
          1: 'Blog',
          2: 'Playthem is a smart e-Commerce app that enables you to create gamified popups to grow your email list, increase sales and revenue with better customer engagement.',
          3: 'Gamification in Sales - Gamification for Everyone',
          4: 'The number of online-stores are growing by the day. A typical online shopper has at least five stores to choose from while making a purchase. If the competition wasn’t already enough, each of these stores are also using the same tactics to reach their target customers. Be it social media, email marketing or even advertising campaigns, everyone is doing everything. So what is the one thing you can do to make your strategy stand out and increase Shopify sales?',
          5: 'Greatest ways to use discounts to increase online-sales in 2021',
          6: 'Today, let’s step into our own shoes as an online shopper. Think about the last time you purchased a product similar to the one you have. Then tell us if you bought both the products from the same store. There’s a high chance that your answer is no.',
          7: 'Gaming push notifications, what they are and why you need them',
          8:
            'If you want to inform users about new products, indicate this in the title. It is better to use stylish text in push notifications about new products than a selling one.' +
            'In newsletters, also announce the usefulness of information for subscribers.'
        }
      }
    },
    fr: {
      translation: {
        startTitle: 'Certains textes de départ',
        startDescription:
          'Vouloir être une douleur dans le cupidatat cillum a été critiqué dans la fuite Duis et dolore magna ne produit' +
          'aucun plaisir résultant. Les noirs excepteur sont cupidatat généreux',
        btnGetStarted: 'Commencez maintenant',
        btnWatch: 'Regarder la vidéo'
      }
    },
    de: {
      translation: {
        startTitle: 'Einige Starttexte',
        startDescription:
          'Willst du ein Schmerz in der Cupidatat sein cillum wurde kritisiert, in der Duis et dolore magna fliehen produziert kein daraus resultierendes Vergnügen.' +
          ' Außergewöhnliche Schwarze sind Cupidata proident.',
        btnGetStarted: 'Jetzt loslegen',
        btnWatch: 'Schau Video'
      }
    },
    ru: {
      translation: {
        startTitle: 'Какой-то текст',
        startDescription:
          'Повседневная практика показывает, что сложившаяся структура организации способствует подготовки и реализации системы обученият, однако забывать, значительной степени обуславливает создание модели развития.',
        btnGetStarted: 'Попробовать сейчас',
        btnWatch: 'Смотреть видео'
      }
    }
  },
  lng: `${localStorage.getItem('lng') || 'en'}`,
  fallbackLng: `${localStorage.getItem('lng') || 'en'}`,

  interpolation: {
    escapeValue: false
  }
});
export default i18next;
