import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledWatchBtn from './StyledComponents';

const WatchBtn = ({ languages }) => {
  const { t } = useTranslation();
  return (
    <StyledWatchBtn languages={languages}>{t('home.btnWatch')}</StyledWatchBtn>
  );
};

export default WatchBtn;
