// This is done by the component because you cannot use
// the useTranslation outside of the component

import { useTranslation } from 'react-i18next';

const NavLinks = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('home.btnHeader_games'),
      link: 'our-games'
    },
    {
      title: t('home.btnHeader_software'),
      link: 'platform'
    },
    {
      title: t('home.btnHeader_pricing'),
      link: 'pricing'
    },
    {
      title: t('home.btnHeader_blog'),
      link: 'blog/page/1'
    }
  ];
};

export default NavLinks;
