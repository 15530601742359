import React from 'react';
import Wrapper, { Img, NavContainer, NavList, Link, Copyright } from './style';
// import logoFooter from '../../shared/assets/icons/logo-footer.svg';
// import NavLinks from '../../shared/constants/navLinks';
import StartBtn from '../Buttons/StartBtn';
import NavLinks from '../../shared/constants/navLinks';

const Footer = () => {
  return (
    <Wrapper>
      <NavContainer>
        <Img />
        <NavList>
          {NavLinks().map((item) => (
            <Link
              activeClassName="navbar-active"
              key={item.link}
              to={`/${item.link}`}
            >
              {item.title}
            </Link>
          ))}
        </NavList>
        <StartBtn />
      </NavContainer>
      <Copyright>@2021 PlayThem</Copyright>
    </Wrapper>
  );
};

export default Footer;
